import {
    ButtonStyles,
    ColumnType,
    DividerStyles,
    ImageStyles,
    InputStyles,
    PaddingStyles,
    PageStyles,
    PromoCodeStyles,
    TextComponentStyles,
    TextStyles,
    defaultStyles,
} from "@cymbal/legos";

import {
    LegoButtonStyle,
    LegoDividerStyle,
    LegoEventStyle,
    LegoFooterStyle,
    LegoFormStyle,
    LegoHeaderStyle,
    LegoImageStyle,
    LegoInputStyle,
    LegoPaddingStyle,
    LegoPageStyle,
    LegoPromoCodeStyle,
    LegoTemplateStyle,
    LegoTextComponentStyle,
    LegoTextStyle,
} from "../models/legos";
import {
    MergedLegoFooterStyle,
    MergedOrganizationLegoStyles,
    NonNullableLegoEventStyle,
    NonNullableLegoFormStyle,
} from "../models/mergedLegos";

export const mergeLegoTemplateStyles = (
    orgTemplateStyles: LegoTemplateStyle,
    templateStyles?: LegoTemplateStyle,
): MergedOrganizationLegoStyles => {
    return {
        id: orgTemplateStyles.id,
        page_style: mergePageStyles(orgTemplateStyles.page_style, templateStyles?.page_style),
        divider_style: mergeDividerStyles(orgTemplateStyles.divider_style, templateStyles?.divider_style),
        divider_padding: mergeLegoPaddingStyles(
            orgTemplateStyles.divider_padding,
            templateStyles?.divider_padding,
            defaultStyles.divider_padding,
        ),
        text_style: mergeTextComponentStyles(orgTemplateStyles.text_style, templateStyles?.text_style),
        button_style: mergeLegoButtonStyles(orgTemplateStyles.button_style, templateStyles?.button_style),
        button_padding: mergeLegoPaddingStyles(
            orgTemplateStyles.button_padding,
            templateStyles?.button_padding,
            defaultStyles.button_padding,
        ),
        image_style: mergeLegoImageStyles(orgTemplateStyles.image_style, templateStyles?.image_style),
        image_padding: mergeLegoPaddingStyles(
            orgTemplateStyles.image_padding,
            templateStyles?.image_padding,
            defaultStyles.image_padding,
        ),
        promo_code_style: mergeLegoPromoCodeStyles(
            orgTemplateStyles.promo_code_style,
            templateStyles?.promo_code_style,
        ),
        promo_code_padding: mergeLegoPaddingStyles(
            orgTemplateStyles.promo_code_padding,
            templateStyles?.promo_code_padding,
            defaultStyles.promo_code_padding,
        ),
        input_style: mergeLegoInputStyles(orgTemplateStyles.input_style, templateStyles?.input_style),
        input_padding: mergeLegoPaddingStyles(
            orgTemplateStyles.input_padding,
            templateStyles?.input_padding,
            defaultStyles.input_padding,
        ),
        form_style: mergeLegoFormStyle(orgTemplateStyles.form_style, templateStyles?.form_style),
        event_style: mergeLegoEventStyles(orgTemplateStyles.event_style, templateStyles?.event_style),
        two_column_event_style: mergeLegoEventStyles(
            orgTemplateStyles.two_column_event_style,
            templateStyles?.two_column_event_style,
            "two",
        ),
        three_column_event_style: mergeLegoEventStyles(
            orgTemplateStyles.three_column_event_style,
            templateStyles?.three_column_event_style,
            "three",
        ),
        horizontal_event_style: mergeLegoEventStyles(
            orgTemplateStyles.horizontal_event_style,
            templateStyles?.horizontal_event_style,
            "two",
        ),
        header_style: mergeLegoHeaderStyles(orgTemplateStyles.header_style, templateStyles?.header_style),
        footer_style: mergeLegoFooterStyles(orgTemplateStyles.footer_style, templateStyles?.footer_style),
    };
};

const mergePageStyles = (
    orgPageStyle: LegoPageStyle | null,
    templatePageStyle: LegoPageStyle | null | undefined,
): PageStyles => ({
    background_color:
        templatePageStyle?.background_color ??
        orgPageStyle?.background_color ??
        defaultStyles.page_style.background_color,
    link_color: templatePageStyle?.link_color ?? orgPageStyle?.link_color ?? defaultStyles.page_style.link_color,
    underline_links:
        templatePageStyle?.underline_links ?? orgPageStyle?.underline_links ?? defaultStyles.page_style.underline_links,
    border_color:
        templatePageStyle?.border_color ?? orgPageStyle?.border_color ?? defaultStyles.page_style.border_color,
    border_width_px:
        templatePageStyle?.border_width_px ?? orgPageStyle?.border_width_px ?? defaultStyles.page_style.border_width_px,
    canvas_color:
        templatePageStyle?.canvas_color ?? orgPageStyle?.canvas_color ?? defaultStyles.page_style.canvas_color,
});

const mergeDividerStyles = (
    orgDividerStyle: LegoDividerStyle | null,
    templateDividerStyle: LegoDividerStyle | null | undefined,
): DividerStyles => ({
    color: templateDividerStyle?.color ?? orgDividerStyle?.color ?? defaultStyles.divider_style.color,
});

const mergeTextComponentStyles = (
    orgTextComponentStyles: LegoTextComponentStyle | null,
    templateTextComponentStyles: LegoTextComponentStyle | null | undefined,
): TextComponentStyles => ({
    title_style: mergeLegoTextStyles(
        orgTextComponentStyles?.title_style,
        templateTextComponentStyles?.title_style,
        defaultStyles.text_style.title_style,
    ),
    title_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.title_padding,
        templateTextComponentStyles?.title_padding,
        defaultStyles.text_style.title_padding,
    ),
    subtitle_style: mergeLegoTextStyles(
        orgTextComponentStyles?.subtitle_style,
        templateTextComponentStyles?.subtitle_style,
        defaultStyles.text_style.subtitle_style,
    ),
    subtitle_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.subtitle_padding,
        templateTextComponentStyles?.subtitle_padding,
        defaultStyles.text_style.subtitle_padding,
    ),
    heading_style: mergeLegoTextStyles(
        orgTextComponentStyles?.heading_style,
        templateTextComponentStyles?.heading_style,
        defaultStyles.text_style.heading_style,
    ),
    heading_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.heading_padding,
        templateTextComponentStyles?.heading_padding,
        defaultStyles.text_style.heading_padding,
    ),
    subheading_style: mergeLegoTextStyles(
        orgTextComponentStyles?.subheading_style,
        templateTextComponentStyles?.subheading_style,
        defaultStyles.text_style.subheading_style,
    ),
    subheading_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.subheading_padding,
        templateTextComponentStyles?.subheading_padding,
        defaultStyles.text_style.subheading_padding,
    ),
    section_style: mergeLegoTextStyles(
        orgTextComponentStyles?.section_style,
        templateTextComponentStyles?.section_style,
        defaultStyles.text_style.section_style,
    ),
    section_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.section_padding,
        templateTextComponentStyles?.section_padding,
        defaultStyles.text_style.section_padding,
    ),
    body_style: mergeLegoTextStyles(
        orgTextComponentStyles?.body_style,
        templateTextComponentStyles?.body_style,
        defaultStyles.text_style.body_style,
    ),
    body_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.body_padding,
        templateTextComponentStyles?.body_padding,
        defaultStyles.text_style.body_padding,
    ),
    accent_style: mergeLegoTextStyles(
        orgTextComponentStyles?.accent_style,
        templateTextComponentStyles?.accent_style,
        defaultStyles.text_style.accent_style,
    ),
    accent_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.accent_padding,
        templateTextComponentStyles?.accent_padding,
        defaultStyles.text_style.accent_padding,
    ),
    caption_style: mergeLegoTextStyles(
        orgTextComponentStyles?.caption_style,
        templateTextComponentStyles?.caption_style,
        defaultStyles.text_style.caption_style,
    ),
    caption_padding: mergeLegoPaddingStyles(
        orgTextComponentStyles?.caption_padding,
        templateTextComponentStyles?.caption_padding,
        defaultStyles.text_style.caption_padding,
    ),
});

export const mergeLegoTextStyles = (
    orgTextStyles: LegoTextStyle | null | undefined,
    templateTextStyle: LegoTextStyle | null | undefined,
    defaultStyle: TextStyles,
): TextStyles => ({
    color: templateTextStyle?.color ?? orgTextStyles?.color ?? defaultStyle.color,
    font_weight: templateTextStyle?.font_weight ?? orgTextStyles?.font_weight ?? defaultStyle.font_weight,
    font_style: templateTextStyle?.font_style ?? orgTextStyles?.font_style ?? defaultStyle.font_style,
    font_size_px: templateTextStyle?.font_size_px ?? orgTextStyles?.font_size_px ?? defaultStyle.font_size_px,
    font_family: templateTextStyle?.font_family ?? orgTextStyles?.font_family ?? defaultStyle.font_family,
    align: templateTextStyle?.align ?? orgTextStyles?.align ?? defaultStyle.align,
});

export const mergeLegoPaddingStyles = (
    orgPaddingStyle: LegoPaddingStyle | null | undefined,
    templatePaddingStyle: LegoPaddingStyle | null | undefined,
    defaultStyle: PaddingStyles,
): PaddingStyles => ({
    bottom_px: templatePaddingStyle?.bottom_px ?? orgPaddingStyle?.bottom_px ?? defaultStyle.bottom_px,
    top_px: templatePaddingStyle?.top_px ?? orgPaddingStyle?.top_px ?? defaultStyle.top_px,
    left_px: templatePaddingStyle?.left_px ?? orgPaddingStyle?.left_px ?? defaultStyle.left_px,
    right_px: templatePaddingStyle?.right_px ?? orgPaddingStyle?.right_px ?? defaultStyle.right_px,
});

export const mergeLegoButtonStyles = (
    orgButtonStyle: LegoButtonStyle | null | undefined,
    templateButtonStyle: LegoButtonStyle | null | undefined,
): ButtonStyles => ({
    border_radius_px:
        templateButtonStyle?.border_radius_px ??
        orgButtonStyle?.border_radius_px ??
        defaultStyles.button_style.border_radius_px,
    background_color:
        templateButtonStyle?.background_color ??
        orgButtonStyle?.background_color ??
        defaultStyles.button_style.background_color,
    height_px: templateButtonStyle?.height_px ?? orgButtonStyle?.height_px ?? defaultStyles.button_style.height_px,
    text_style: mergeLegoTextStyles(
        orgButtonStyle?.text_style,
        templateButtonStyle?.text_style,
        defaultStyles.button_style.text_style,
    ),
});

const mergeLegoImageStyles = (
    orgImageStyle: LegoImageStyle | null | undefined,
    templateImageStyle: LegoImageStyle | null | undefined,
): ImageStyles => ({
    border_radius_px:
        templateImageStyle?.border_radius_px ??
        orgImageStyle?.border_radius_px ??
        defaultStyles.image_style.border_radius_px,
    background_color:
        templateImageStyle?.background_color ??
        orgImageStyle?.background_color ??
        defaultStyles.image_style.background_color,
});

export const mergeLegoPromoCodeStyles = (
    orgPromoCodeStyle: LegoPromoCodeStyle | null | undefined,
    templatePromoCodeStyle: LegoPromoCodeStyle | null | undefined,
): PromoCodeStyles => ({
    border_radius_px:
        templatePromoCodeStyle?.border_radius_px ??
        orgPromoCodeStyle?.border_radius_px ??
        defaultStyles.promo_code_style.border_radius_px,
    background_color:
        templatePromoCodeStyle?.background_color ??
        orgPromoCodeStyle?.background_color ??
        defaultStyles.promo_code_style.background_color,
    height_px:
        templatePromoCodeStyle?.height_px ?? orgPromoCodeStyle?.height_px ?? defaultStyles.promo_code_style.height_px,
    text_style: mergeLegoTextStyles(
        orgPromoCodeStyle?.text_style,
        templatePromoCodeStyle?.text_style,
        defaultStyles.promo_code_style.text_style,
    ),
});

const mergeLegoInputStyles = (
    orgInputStyle: LegoInputStyle | null | undefined,
    templateInputStyle: LegoInputStyle | null | undefined,
): InputStyles => ({
    border_radius_px:
        templateInputStyle?.border_radius_px ??
        orgInputStyle?.border_radius_px ??
        defaultStyles.input_style.border_radius_px,
    background_color:
        templateInputStyle?.background_color ??
        orgInputStyle?.background_color ??
        defaultStyles.input_style.background_color,
    height_px: templateInputStyle?.height_px ?? orgInputStyle?.height_px ?? defaultStyles.input_style.height_px,
    text_style: mergeLegoTextStyles(
        orgInputStyle?.text_style,
        templateInputStyle?.text_style,
        defaultStyles.input_style.text_style,
    ),
});

const defaultFormStyles: NonNullableLegoFormStyle = {
    button_text: "Register",
};

const mergeLegoFormStyle = (
    orgFormStyle: LegoFormStyle | null | undefined,
    templateFormStyle: LegoFormStyle | null | undefined,
): NonNullableLegoFormStyle => ({
    button_text: templateFormStyle?.button_text ?? orgFormStyle?.button_text ?? defaultFormStyles.button_text,
});

const defaultEventStyles: NonNullableLegoEventStyle = {
    button_text: "Tickets",
    time_format: "Monday, January 2, 3:04PM",
    location_format: "name, address",
    door_open_time_format: "Monday, January 2, 3:04PM",
    description_character_limit: 250,
    name_text_type: "subheading",
    datetime_text_type: "caption",
    location_text_type: "body",
    description_text_type: "caption",
    door_open_time_text_type: "caption",
    include_datetime: true,
    include_image: true,
    include_name: true,
    include_button: true,
    include_location: false,
    include_description: false,
    include_door_open_time: false,
    name_use_caps: false,
    time_format_use_caps: false,
    button_use_caps: false,
    location_use_caps: false,
    description_use_caps: false,
    door_open_time_use_caps: false,
    horizontal_is_reversed: false,
    ordinals: {
        image: 1,
        name: 2,
        datetime: 3,
        button: 4,
        location: 5,
        description: 6,
        door_open_time: 7,
    },
};

const getDefaultEventStyles = (columnType?: ColumnType): NonNullableLegoEventStyle => ({
    ...defaultEventStyles,
    time_format:
        columnType === "three"
            ? "Mon 1/2"
            : columnType === "two"
              ? "Mon, Jan 2, 3:04PM"
              : defaultEventStyles.time_format,
});

const mergeLegoEventStyles = (
    orgEventStyle: LegoEventStyle | null | undefined,
    templateEventStyle: LegoEventStyle | null | undefined,
    columnType?: ColumnType,
): NonNullableLegoEventStyle =>
    !orgEventStyle
        ? getDefaultEventStyles(columnType)
        : {
              time_format:
                  templateEventStyle?.time_format ?? orgEventStyle.time_format ?? defaultEventStyles.time_format,
              location_format:
                  templateEventStyle?.location_format ??
                  orgEventStyle.location_format ??
                  defaultEventStyles.location_format,
              door_open_time_format:
                  templateEventStyle?.door_open_time_format ??
                  orgEventStyle.door_open_time_format ??
                  defaultEventStyles.door_open_time_format,
              description_character_limit:
                  templateEventStyle?.description_character_limit ??
                  orgEventStyle.description_character_limit ??
                  defaultEventStyles.description_character_limit,
              button_text:
                  templateEventStyle?.button_text ?? orgEventStyle.button_text ?? defaultEventStyles.button_text,
              name_text_type:
                  templateEventStyle?.name_text_type ??
                  orgEventStyle.name_text_type ??
                  defaultEventStyles.name_text_type,
              datetime_text_type:
                  templateEventStyle?.datetime_text_type ??
                  orgEventStyle.datetime_text_type ??
                  defaultEventStyles.datetime_text_type,
              location_text_type:
                  templateEventStyle?.location_text_type ??
                  orgEventStyle.location_text_type ??
                  defaultEventStyles.location_text_type,
              description_text_type:
                  templateEventStyle?.description_text_type ??
                  orgEventStyle.description_text_type ??
                  defaultEventStyles.description_text_type,
              door_open_time_text_type:
                  templateEventStyle?.door_open_time_text_type ??
                  orgEventStyle.door_open_time_text_type ??
                  defaultEventStyles.door_open_time_text_type,
              include_name:
                  templateEventStyle?.include_name ?? orgEventStyle.include_name ?? defaultEventStyles.include_name,
              include_image:
                  templateEventStyle?.include_image ?? orgEventStyle.include_image ?? defaultEventStyles.include_image,
              include_datetime:
                  templateEventStyle?.include_datetime ??
                  orgEventStyle.include_datetime ??
                  defaultEventStyles.include_datetime,
              include_button:
                  templateEventStyle?.include_button ??
                  orgEventStyle.include_button ??
                  defaultEventStyles.include_button,
              include_location:
                  templateEventStyle?.include_location ??
                  orgEventStyle.include_location ??
                  defaultEventStyles.include_location,
              include_description:
                  templateEventStyle?.include_description ??
                  orgEventStyle.include_description ??
                  defaultEventStyles.include_description,
              include_door_open_time:
                  templateEventStyle?.include_door_open_time ??
                  orgEventStyle.include_door_open_time ??
                  defaultEventStyles.include_door_open_time,
              name_use_caps:
                  templateEventStyle?.name_use_caps ?? orgEventStyle.name_use_caps ?? defaultEventStyles.name_use_caps,
              time_format_use_caps:
                  templateEventStyle?.time_format_use_caps ??
                  orgEventStyle.time_format_use_caps ??
                  defaultEventStyles.time_format_use_caps,
              button_use_caps:
                  templateEventStyle?.button_use_caps ??
                  orgEventStyle.button_use_caps ??
                  defaultEventStyles.button_use_caps,
              location_use_caps:
                  templateEventStyle?.location_use_caps ??
                  orgEventStyle.location_use_caps ??
                  defaultEventStyles.location_use_caps,
              description_use_caps:
                  templateEventStyle?.description_use_caps ??
                  orgEventStyle.description_use_caps ??
                  defaultEventStyles.description_use_caps,
              door_open_time_use_caps:
                  templateEventStyle?.door_open_time_use_caps ??
                  orgEventStyle.door_open_time_use_caps ??
                  defaultEventStyles.door_open_time_use_caps,
              horizontal_is_reversed:
                  templateEventStyle?.horizontal_is_reversed ??
                  orgEventStyle.horizontal_is_reversed ??
                  defaultEventStyles.horizontal_is_reversed,
              ordinals: templateEventStyle?.ordinals ?? orgEventStyle.ordinals ?? defaultEventStyles.ordinals,
          };

const mergeLegoHeaderStyles = (
    orgHeaderStyle: LegoHeaderStyle | null | undefined,
    templateHeaderStyle: LegoHeaderStyle | null | undefined,
) => templateHeaderStyle ?? orgHeaderStyle ?? null;

export const mergeLegoFooterStyles = (
    orgFooterStyle: LegoFooterStyle | null | undefined,
    templateFooterStyle: LegoFooterStyle | null | undefined,
): MergedLegoFooterStyle => ({
    copyright: templateFooterStyle?.copyright ?? orgFooterStyle?.copyright ?? undefined,
    business_address: templateFooterStyle?.business_address ?? orgFooterStyle?.business_address ?? undefined,
    use_socials:
        templateFooterStyle?.use_socials ??
        orgFooterStyle?.use_socials ??
        defaultStyles.footer_style.use_socials ??
        true,
    icon_style: templateFooterStyle?.icon_style ?? orgFooterStyle?.icon_style ?? defaultStyles.footer_style.icon_style,
    ordinals: templateFooterStyle?.ordinals ?? orgFooterStyle?.ordinals ?? defaultStyles.footer_style.ordinals,
    component_group: templateFooterStyle?.component_group ?? orgFooterStyle?.component_group ?? null,
});

export interface EventComponentsToSort<T> {
    image?: T;
    name: T;
    datetime: T;
    button: T;
    location?: T;
    description?: T;
    door_open_time?: T;
}

export function sortByEventOrdinals<T>(objects: EventComponentsToSort<T>, eventStyles: NonNullableLegoEventStyle): T[] {
    const eventComponents: { object: T; ordinal: number }[] = [];

    if (eventStyles.include_name) {
        eventComponents.push({
            object: objects.name,
            ordinal: eventStyles.ordinals.name,
        });
    }

    if (eventStyles.include_datetime) {
        eventComponents.push({
            object: objects.datetime,
            ordinal: eventStyles.ordinals.datetime,
        });
    }

    if (eventStyles.include_button) {
        eventComponents.push({
            object: objects.button,
            ordinal: eventStyles.ordinals.button,
        });
    }

    if (eventStyles.include_image && !!objects.image) {
        eventComponents.push({
            object: objects.image,
            ordinal: eventStyles.ordinals.image,
        });
    }

    if (eventStyles.include_location && !!objects.location) {
        eventComponents.push({
            object: objects.location,
            ordinal: eventStyles.ordinals.location,
        });
    }

    if (eventStyles.include_description && !!objects.description) {
        eventComponents.push({
            object: objects.description,
            ordinal: eventStyles.ordinals.description,
        });
    }

    if (eventStyles.include_door_open_time && !!objects.door_open_time) {
        eventComponents.push({
            object: objects.door_open_time,
            ordinal: eventStyles.ordinals.door_open_time,
        });
    }

    return eventComponents.sort((a, b) => a.ordinal - b.ordinal).map(({ object }) => object);
}
